import React, { useState } from "react";
import PortableText from "react-portable-text";
import Container from "../Container/Container";
import Image from "gatsby-plugin-sanity-image";

import InView from "../InView";
import { Link } from "gatsby";

// import React Player
import ReactPlayer from "react-player";

// import Swiper core and required modules
import { Navigation, A11y, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

function NewsPost(props) {
  const {
    _rawBody,
    title,
    mainImage,
    relatedPost,
    mediaType,
    slideshow,
    videoUrl,
  } = props;
  const [loaded, setLoaded] = useState(false);

  const SlideContent = ({ item }) => (
    <>
      <Image
        {...item}
        width={1200}
        height={800}
        className={`lazy ` + (loaded && " loaded")}
        onLoad={() => setLoaded(true)}
      />
    </>
  );

  return (
    <>
      <Container>
        <InView>
          <div className="container flex flex-col md:grid md:grid-cols-12 mt-[78px] md:mt-[88px] border-b border-grey-default box-border min-h-[70vh]">
            <div className="md:col-span-9 xl:col-span-7 pb-[10px] md:pb-[120px]">
              <article className="copy md:pr-10">
                <h1>{title}</h1>
                {mediaType === "main" && (
                  <Image
                    {...mainImage}
                    width={1200}
                    alt={mainImage.alt}
                    className={`lazy ` + (loaded && " loaded")}
                    onLoad={() => setLoaded(true)}
                  />
                )}
                {mediaType === "slideshow" && (
                  <Swiper
                    modules={[Navigation, Autoplay, A11y, EffectFade]}
                    slidesPerView={1}
                    navigation
                    loop={true}
                    effect={"fade"}
                    speed={1000}
                    autoplay={{
                      delay: 6000,
                      disableOnInteraction: false,
                    }}
                  >
                    {slideshow.images.map((item, index) => (
                      <SwiperSlide key={index}>
                        <SlideContent item={item} key={index} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
                {mediaType === "video" && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={videoUrl}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
                <div className="copy copy--container md:max-w-[80%] mt-6 md:mt-[55px]">
                  {_rawBody && (
                    <PortableText
                      content={_rawBody}
                      serializers={{
                        internalLink: ({ reference, children }) => {
                          const href = `/${reference._type}/${reference.slug.current}`;
                          return <Link to={href}>{children}</Link>;
                        },
                        link: ({ href, blank, children }) => {
                          return (
                            <a
                              href={href}
                              target={blank ? "_blank" : "_self"}
                              rel={blank ? "noreferrer" : undefined}
                            >
                              {children}
                            </a>
                          );
                        },
                      }}
                    />
                  )}
                </div>
              </article>
            </div>
            {relatedPost.length > 0 && relatedPost && (
              <>
                <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                <div className="md:col-span-3 xl:col-start-10 md:pl-10 pt-6 md:pt-0 md:border-l md:border-grey-default md:box-border">
                  <h2>Explore news</h2>
                  <Link
                    className="mt-2 mb-[35px] text-sm text-grey-base link"
                    to="/news"
                  >
                    View all news
                  </Link>
                  {relatedPost.map((related) => (
                    <div className="mb-10 md:mb-[55px]" key={related.title}>
                      {related.mainImage && related.mainImage.asset && (
                        <Link
                          className="overflow-hidden block"
                          to={`/news/${related.slug.current}`}
                        >
                          <Image
                            {...related.mainImage}
                            width={600}
                            alt=" "
                            className={
                              `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
                              (loaded && " loaded")
                            }
                            onLoad={() => setLoaded(true)}
                          />
                        </Link>
                      )}
                      <h3 className="font-serif text-lg my-4 md:mt-10 md:mb-[15px]">
                        {related.title}
                      </h3>
                      <div className="text-sm">
                        {related._rawExcerpt && (
                          <PortableText content={related._rawExcerpt} />
                        )}
                      </div>
                      <Link
                        className="mt-3 md:mt-6 text-sm text-grey-base link"
                        to={`/news/${related.slug.current}`}
                      >
                        Read more
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </InView>
      </Container>
    </>
  );
}

export default NewsPost;
