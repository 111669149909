import React from "react";
import { graphql } from "gatsby";
import Container from "../components/Container/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import NewsPost from "../components/NewsPost/NewsPost";
import Seo from "../components/SEO/SEO";
import { toPlainText } from "../lib/helpers";

import Helmet from "react-helmet";

export const query = graphql`
  query NewsPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      relatedPost {
        mainImage {
          ...Image
          alt
        }
        title
        slug {
          current
        }
        _rawExcerpt(resolveReferences: { maxDepth: 5 })
      }
      mainImage {
        ...Image
        alt
      }
      slideshow {
        images {
          ...Image
        }
      }
      mediaType
      videoUrl
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const NewsPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  return (
    <>
      {errors && <Seo title="GraphQL Error" />}
      {post && (
        <Seo
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      <Helmet
        bodyAttributes={{
          class: "no-banner",
        }}
      />

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <NewsPost {...post} />}
    </>
  );
};

export default NewsPostTemplate;
